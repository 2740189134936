<template>
  <register-container>
    <template slot="header">Please select an intake to complete</template>
    <div class="choice-container d-flex justify-space-around mt-6">
      <div>
        <hover-button @click="setFormChoice(SIMPLE)" :disabled="loading">Simple</hover-button>
      </div>
      <div>
        <hover-button @click="setFormChoice(DETAILED)" :disabled="loading">Detailed</hover-button>
      </div>
    </div>
  </register-container>
</template>
<style scoped>
.choice-container {
  width: 100%;
}
</style>
<script>
import HoverButton from '../components/HoverButton.vue';
import RegisterContainer from '../components/RegisterContainer.vue';

const SIMPLE = 'simple';
const DETAILED = 'detailed';
export default {
  name: 'RegisterIntakeChoice',
  components: { HoverButton, RegisterContainer },
  data() {
    return {
      loading: false,
      SIMPLE,
      DETAILED,
      SANDBOX_FORMS: {
        [SIMPLE]: 'p_3Tp03q7acPETSdcpaICF',
        [DETAILED]: 'p_yZLSeSElduuBp6zEqevS',
      },
      QA_FORMS: {
        [SIMPLE]: 'p_ocWqLI7o605KdI7ECyoG',
        [DETAILED]: 'p_hxuHOHFd2tmzL08En8wD',
      },
      PROD_FORMS: {
        [SIMPLE]: 'p_VKAaxnFvfkHghoWM70Rp',
        [DETAILED]: 'p_YGtQEw8pLP04Sm9aUsDs',
      },
    };
  },
  methods: {
    getInitialState() {
      const config = this.$store.getters.getConfig(this.$options.name);
      if (!config) return;
      Object.keys(config).forEach((option) => {
        this[option] = config[option];
      });
    },
    async setFormChoice(choice) {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      const { SANDBOX_FORMS, QA_FORMS, PROD_FORMS } = this;

      const sourceUrl = window.location.href;

      let form;

      if (/portal.sentryhealth.ca/g.test(sourceUrl)) {
        form = PROD_FORMS[choice];
      } else if (/portal.qa.sentryhealth.dev/g.test(sourceUrl)) {
        form = QA_FORMS[choice];
      } else {
        form = SANDBOX_FORMS[choice];
      }

      await this.$store.commit('setRegistration', { form });

      this.$emit('next');
      this.loading = false;
    },
  },
};
</script>
